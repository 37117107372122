<template>
  <div class="w_course_detail">
    <div class="l_area">
      <div class="top_info">
        <img class="img" :src="_this.utils.HandleUrl(dto.img_url,1)" />
        <div class="info">
          <div class="title">{{ dto.title }}</div>
          <div class="num">
            <span class="date">{{ dto.total_learn }}</span>
            <span>人正在学习</span>
          </div>
          <router-link :to="{ name: 'vediodetail', params: { id: course_id ,index:0}}" class="btn">立即学习</router-link>
        </div>
      </div>

      <div class="bot_desc">
        <div class="switch_box">
          <div :class="['item', switch_tab==1?'curr':'']" @click="switch_tab=1">课程简介</div>
          <div :class="['item', switch_tab==2?'curr':'']" @click="switch_tab=2">课程选集</div>
        </div>
        <template v-if="switch_tab==1">
          <div class="descCon" v-html="_this.utils.HandleContentImg(dto.description,1)"></div>
        </template>

        <template v-if="switch_tab==2">
          <ul class="list">
            <li class="item" v-for="(item,index) in dto.course_video_list" :key="index">
              <router-link :to="{ name: 'vediodetail', params: { id: course_id, index: index }}" class="btn">{{ item.title }}</router-link>
            </li>
          </ul>
        </template>
      </div>
    </div>
    <div class="r_area">
      <div class="r_area_title">相关课程</div>
      <router-link :to="{ name:'coursedetail',params: {id:item.id}}" class="item" v-for="(item,index) in list_rec" :key="index">
        <img class="img" :src="_this.utils.HandleUrl(item.img_url,1)" />
        <div class="title">{{ item.title }}</div>
        <div class="tips">{{ item.total_learn }}人学习 可试看</div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance,watch } from 'vue';
import { useRoute } from 'vue-router';

let switch_tab = ref(1);  //switch_tab 1课程简介 2课程选集
const $route = useRoute();
let course_id = $route.params.id;
const _this = getCurrentInstance().appContext.config.globalProperties;
let dto = ref({});
let list_rec = ref([]);

watch(() => $route.params,()=>{
  course_id = $route.params.id;
  GetCourseDetail();
  GetRecList();
})

function GetCourseDetail() {
  var url = "/Open/GetCourseDetail";
  _this.$http.post(url, { id: course_id }).then(function (res) {
    if (res.data.status == 1) {
      dto.value = res.data.ds;
    }
  }.bind(this)).catch(function () {
  });
}
function GetRecList() {
  var url = "/Open/GetCourseList";
  _this.$http.post(url, { pagesize:4, rec_by_id: course_id }).then(function (res) {
    if (res.data.status == 1) {
      list_rec.value = res.data.ds;
    }
  }.bind(this)).catch(function () {
  });
}
GetRecList();
GetCourseDetail();


</script>